.About-header {
    padding: 2rem;
  }
  
.t_border {
border-top: 2px solid #ddd;
}

.display-4 {
font-size: 2.5rem;
font-weight: 300;
}

.img-fluid {
max-width: 100%;
height: auto;
}

.backend-image {
max-width: 100%;
height: 100%;
}


.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

@media (min-width: 992px) {
    .display-4 {
    font-size: 3rem;
    }
}
  